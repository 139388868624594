<!--排课表编辑  -->
<template>
    <div class="EditTimetable">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">编辑课程</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单 -->
        <el-form ref="form" :model="form" :rules="rules" id="form-1" class="" inline label-width="150px">
            <el-form-item label="序号">
                <el-input v-model="form.number" placeholder="请输入序号"></el-input>
            </el-form-item>
            <el-form-item label="排课摘要" >
                <el-input v-model="form.abstract"  placeholder="请输入排课摘要"></el-input>
            </el-form-item>
            <el-form-item label="培训班编码" >
                <el-input v-model="form.code"  placeholder="请输入培训班编码"></el-input>
            </el-form-item>
            <el-form-item label="培训班名称" prop="className">
                <el-input  v-model="form.className" placeholder="请输入培训班名称"></el-input>
            </el-form-item>
            <el-form-item label="上课日期" prop="classDate">
                 <el-date-picker
                    value-format="yyyy-MM-dd"
                      v-model="form.classDate"
                      type="date"
                      placeholder="请选择上课日期">
                    </el-date-picker>
            </el-form-item>
            <el-form-item label="上课时间" prop="classTime">
                <el-time-picker
                    v-model="form.classTime"
                    value-format="HH:mm"
                    format='HH:mm'
                    :picker-options="{
                      selectableRange: '00:00:00 - 23:59:59'
                    }"
                    placeholder="请选择上课时间">
                  </el-time-picker>
            </el-form-item>
            <el-form-item label="课时数(hr)" prop="classHour">
                <el-input  v-model="form.classHour" placeholder="请输入课时数"></el-input>
            </el-form-item>
            <el-form-item label="课类" >
                <el-select v-model="form.classType" placeholder="请选择课类">
                    <el-option
                      v-for="item in form.opt"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="状态" >
                <el-select v-model="form.condition" placeholder="请选择状态">
                    <el-option
                      v-for="item in form.stateChose"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="备注说明" >
                <el-input v-model="form.remarks" type="textarea" class="textArea-width"  placeholder="请输入"></el-input>
            </el-form-item>
        </el-form>
        <div class="" style="width: 240px;text-align: right;">
            <span style="color: #D51010;">注：间隔天数7</span>
        </div>
        <el-divider></el-divider>
        <!-- 底部 提交与取消 -->
        <div slot="footer" class="dialog-footer">
            <el-button style="background-color: #18BC9C;color: #FFFFFF;" @click="submitForm('form')">提 交</el-button>
            <el-button class="btn" @click="resetForm('form')">取 消</el-button>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
               form:{
                 number:"",//序号
                 abstract:"",//排课摘要
                 code:"",//培训班编码
                 className:"",//培训班名称
                 classDate:"",//上课日期
                 classTime:"",//上课时间
                 classHour:"",//课时数
                 classType:"",//课类
                 opt:[
                     {value: 1,label: '正常课'},
                     {value: 2,label: '补课'},
                 ],//课类选择
                 condition:"",//状态
                 stateChose:[
                     {value: 1,label: '可用'},
                     {value: 2,label: '取消'},
                     {value: 3,label: '已授课'},
                 ],//状态选择
                 remarks:""//备注说明 
               },
               rules:{
                   className:[
                       { required: true, message: '培训班名称不能为空', trigger: 'blur' },
                   ],
               },
               bool:false
            }
        },
        created() {
            // 初次渲染
            this.$request({
                url:'/api/class_/scheduleDetail',
                method:'POST',
                data:{
                    id:this.$route.query.id
                }
            }).then(res=>{
                if(res.code==1){
                    console.log(res,'初次渲染')
                    let form = this.form
                    let data = res.data
                    form.number=data.soft//序号
                    form.abstract=data.abstract//排课摘要
                    form.code=data.number//培训班编码
                    form.className=data.class_name//培训班名称
                    form.classDate=data.course_date//上课日期
                    form.classTime=data.course_time//上课时间
                    form.classHour=data.course_duration//课时数
                    form.classType=data.course_type//课类
                    form.condition=data.course_status//状态
                    form.remarks=data.remark//备注说明 
                }
            })
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
            submitForm(form) { //提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        console.log(this.form)
                        if(this.bool){
                            return
                        }
                        this.bool=true
                        let form = this.form
                        this.$request({
                            url:'/api/class_/scheduleEdit',
                            method:'POST',
                            data:{
                               id:this.$route.query.id,
                               soft:form.number,//序号
                               abstract:form.abstract,//排课摘要
                               number:form.code,//培训班编码
                               course_date:form.classDate,//上课日期
                               course_time:form.classTime,//上课时间
                               class_name:form.className,//培训课名称
                               course_type:form.classType,//课类
                               course_status:form.condition,//状态
                               course_duration:form.classHour,//课时数
                               remark:form.remarks//备注说明
                            }
                        }).then(res=>{
                            if(res.code==1){
                                this.$message({
                                    message: '编辑列表成功',
                                    type: 'success'
                                })
                                setTimeout(() => {
                                    this.goBack()
                                }, 1500)
                            }else{
                                this.$message({
                                    message: res.msg,
                                    type: 'error'
                                })
                                setTimeout(() => {
                                    this.goBack()
                                }, 1500)
                            }
                        }).catch(()=>{
                            this.bool=false
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) { //取消
                this,goBack()
                this.$refs[form].resetFields();
            },
        }
    }
</script>

<style scoped="scoped">
    .EditTimetable{
        width: 100%;
        height: 100%;
    }
    .top {
        padding-top: 25px;
        margin: 0px 24px 59px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    
    ::v-deep .el-form-item__label {
        color: #000000;
    }
    
    .el-input {
        width: 300px;
        height: 37px !important;
    }
    
    .el-select {
        width: 300px;
    }
    
    ::v-deep .el-input.is-disabled .el-input__inner {
        background-color: #FFFFFF;
    }
    
    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-bottom: 20px;
    }
    
    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
</style>
